import React, { createContext, useCallback, useMemo, useState } from 'react'
import styled from 'styled-components'

import { BUTTON_COLOR, BUTTON_SIZE } from '../../support'
import HomeButton from './Buttons/HomeButton';

export const FaqsWithButtonsContext = createContext({})

const FaqsWithButtons = ({subHeader, page, faqsLength, children, width}) => {

	const faqs = useMemo(() => {
		return page.faqs ? [...page.faqs] : [...page.faq] // ...page.faq is for the credit-score page
	}, [page.faqs, page.faq])

	// const [loadMore, setLoadMore] = useState(false)
	// const [faqsArray, setFaqsArray] = useState(faqs ? faqs : null)

	// const handleClickLoadMore = useCallback(() => {
	// 	setLoadMore(!loadMore)
	// 	if (!loadMore) {
	// 		setFaqsArray(faqs.slice(0,faqsLength))
	// 	} else {
	// 		setFaqsArray(faqs)
	// 	}
	// }, [loadMore, setLoadMore, setFaqsArray,faqs, faqsLength])




	/*
	** This is the original code for initially show 6 faqs and then load more
	*/
	const [loadMore, setLoadMore] = useState(true)
	const [faqsArray, setFaqsArray] = useState(faqs ? faqs.slice(0,faqsLength) : null)

	const handleChangeFaqsArray = useCallback(() => {
		if (!loadMore) {
			setFaqsArray(faqs.slice(0,faqsLength))
		} else {
			setFaqsArray(faqs)
		}
	}, [loadMore, faqs, faqsLength])
	const handleClickLoadMore = useCallback(() => {
		setLoadMore(!loadMore)
		handleChangeFaqsArray()
	}, [loadMore, setLoadMore, handleChangeFaqsArray])



	return (
		<Wrapper>
			<Faq width={width} id="FAQs">
				<h2>Frequently Asked Questions</h2>
				<p>{subHeader}</p>
				{faqs && 
					<FaqsWithButtonsContext.Provider value={faqsArray}>
						{children}
					</FaqsWithButtonsContext.Provider>
				}

			</Faq>
			<Buttons>
				{faqs && <div style={{display: faqs.length <= faqsLength ? 'none' : 'block'}}>				
					<HomeButton
						height={BUTTON_SIZE.HEIGHT_L}
						padding={BUTTON_SIZE.PADDING_L}
						fontSize={BUTTON_SIZE.FONT_SIZE_L}
						background={BUTTON_COLOR.GRAY}
						backgroundHover={BUTTON_COLOR.GRAY_HOVER}
						color={BUTTON_COLOR.TEXT_COLOR_BLUE}
						text={loadMore ? 'Load More' : 'Show Less'}
						handleClick={handleClickLoadMore}
					/>
				</div>}
				<div className="view-all">
					<a href="/marketplace/help">View All</a>
				</div>
			</Buttons>
		</Wrapper>
	)
}
const Wrapper = styled.div`

`
const Faq = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	max-width: ${props => props.width || '1020px'};
	margin: 0 auto;
	padding-bottom: 60px;
	@media screen and (max-width: 991px) {
		width: 100%;
	};

	h2 {
		text-align: center; 
		font-size: 40px;
		@media screen and (max-width: 991px) {
			font-size: 32px;
		};

	};
	p {
		text-align: center;
		font-size: 20px;
		margin: 0 0 40px 0;
	}
`
const Buttons = styled.div`
	display: flex;
	justify-content: center;
	align-Items: center;
	padding-bottom: 120px;
	font-family: PoppinsBold;
	div {
		padding-left: 24px;
	}
	a {
		font-size: 20px;
		&:hover {
			color: inherit;
		}
	}
	@media screen and (max-width: 767px) {
		.view-all {
			a {
				font-size: 15px;
			}
		}
	}
	@media screen and (max-width: 479px) {
		flex-direction: column;
		div {
			padding-left: 0;
		}
		.view-all {
			margin-top: 40px;
		}
	}
`

export default FaqsWithButtons