import React from 'react'
import styled from 'styled-components'
import { MdCheckCircleOutline } from 'react-icons/md'

import ContentContainer from '../../../UI/ContentContainer'
import S1App from '../../../UI/S1App'
import FaqsWithButtons from '../../../UI/FaqsWithButtons'
import QuestionsAccordion from '../../../UI/QuestionsAccordion'

const PersonalLoansFaqs = ({subHeader, page}) => {

	return (
		<ContentContainer>
			<FaqsWithButtons subHeader={subHeader} page={page} faqsLength={5}>
				<QuestionsAccordion />
			</FaqsWithButtons>
			{/* <S1App 
				header={'The new SocietyOne app'} 
				subHeader={'Making managing your personal loans easier. Personal loan customers can:'}
			>
				<Text>
					<Item>
            <p>View loan balances</p>
          </Item>
          <Item>
            <p>Request a loan statement</p>
          </Item>
          <Item>
            <p>Find out how to make an extra payment</p>
          </Item>
          <Item>
            <p>Request a payout figure and more</p>
          </Item>
				</Text>
			</S1App> */}
		</ContentContainer>
	)
}

const Text =styled.div`
	display: flex; 
	flex-direction: column; 
	justify-content: flex-start; 
	align-items: flex-start;
`
const Tick = styled.div`
	display: flex;
	justify-content: flex-start;
	align-items: center;
	.icon {
		color: rgb(29 188 110);
		margin: 0 10px 10px 0;
	};
	p {
		margin: 0 0 12px 0;
	}
`
const Item = ({ children }) => {
	return (
		<Tick>
			<MdCheckCircleOutline className='icon' />
			{children}
		</Tick>
	)
}
export default PersonalLoansFaqs