import React, { useContext } from 'react'
import { Accordion, AccordionSummary, AccordionDetails } from '@mui/material'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import styled from 'styled-components'
import { FaqsWithButtonsContext } from './FaqsWithButtons'

const QuestionsAccordion = ({faqs}) => {

	const faqsArray = useContext(FaqsWithButtonsContext)
	const faqsArraySelector = faqs || faqsArray
	return (

		<>
			{faqsArraySelector && faqsArraySelector.map((item, index) =>
				<Accordion disableGutters style={{ boxShadow: 'none', borderBottom: '1px solid rgb(245 245 245)', width: '100%' }} key={index}>
					<AccordionSummary
						expandIcon={<ExpandMoreIcon />}
						aria-controls="panel2a-content"
						style={{ padding: '0', margin: '0' }}
					>
						<Header>
							{item.question}
						</Header>
					</AccordionSummary>
					<AccordionDetails style={{ padding: '0', margin: '0' }}>
						<Content dangerouslySetInnerHTML={{ __html: item.answer.childMarkdownRemark.html }} />
					</AccordionDetails>
				</Accordion>
			)}
		</>
	)
}

const Header = styled.h3`
  color: rgb(38 75 101);
	font-size: 21px;
	padding: 20px 0;
	margin: 10px 0;  
	@media screen and (max-width: 480px) {
		font-size: 18px;
	};

`

const Content = styled.div`
  color: rgb(38 75 101);
	font-size: 16px; 
	width: 960px;

	@media screen and (max-width: 991px) {
		width: 100%;
	};

	p {
		font-size: 18px !important; 
		text-align: left !important;
		@media screen and (max-width: 991px) {
			width: 100% !important; 
			font-size: 16px !important; 

		};
	}
`

export default QuestionsAccordion
