import React from 'react'
import styled from 'styled-components'
import { StaticImage } from "gatsby-plugin-image"

import AppStoreLogo from '../../assets/images/svg/app-store-logo.svg'
import GooglePlayLogo from '../../assets/images/svg/google-play-logo.svg'

const S1App = ({header, subHeader, children, imgWidth, wrapperWidth, background, color}) => {
  return (
    <S1AppWrapper wrapperWidth={wrapperWidth} background={background} color={color}>
      <div className='wrapper'>
        <div className='text'>
          <h2>{header}</h2>
          <p className='head'>{subHeader}</p>
					{children}
        </div>
        <div className='appStore'>
					<a
						rel="noopener noreferrer"
						target="_blank"
						href="https://apps.apple.com/au/app/societyone/id1532681841"
					>
						<AppStoreLogo />
					</a>
					<a
						rel="noopener noreferrer"
						target="_blank"
						href="https://play.google.com/store/apps/details?id=com.societyone.app"
					>
						<GooglePlayLogo />
					</a>
        </div>
      </div>
      <PhoneImg imgWidth={imgWidth}>
        <StaticImage src='../../assets/images/old-phone.png' alt="phone" placeholder='none'/>
      </PhoneImg>
    </S1AppWrapper>
  )
}
const S1AppWrapper = styled.div`
	color: ${props => props.color || null};
	margin: 0 auto;
	padding: 32px 32px 0 32px;
	background: ${props => props.background || 'rgb(237 250 243)'};
	display: flex;
	justify-content: space-between;
	align-items: center;
	max-width: ${props => props.wrapperWidth || '1020px'};
	border-radius: 16px;
	@media screen and (max-width: 991px) {
		max-width: 100%;
		display: flex;
		${'' /* justify-content: space-between; */}
		flex-direction: column; 
		align-items: center;
	};

	.wrapper {
		width: 50%; 
		margin: 0 30px; 
		display: flex; 
		flex-direction: column; 
		justify-content: space-between; 
		align-items: flex-start; 
		padding: 32px 0 40px 0; 
		${'' /* height: 572px; */}
		@media screen and (max-width: 991px) {
			width: 100%; 
		};

		.text {
			display: flex; 
			flex-direction: column; 
			justify-content: flex-start; 
			align-items: flex-start;
			h2 {
				font-size: 32px; 
				margin: 0 0 28px 0;
			};
			.head {
				font-size: 20px;
			};
		};
		.appStore {
			display: flex; 
			justify-content: flex-start; 
			align-items: center;
			margin-Top: 20px;
			a {
				height: 40px; 
				width: auto; 
				padding-right: 16px;
			};
			@media screen and (max-width: 480px) {
				flex-direction: column;
				justify-content: center; 
				align-items: start;
				a {
					margin: 10px 0;
				}
			};

		}
		@media screen and (min-width: 480px) and (max-width: 767px) {
			padding-left: 24px;
			padding-right: 24px;
		}
	};
`
const PhoneImg = styled.div`
	max-width: ${props => props.imgWidth || "458px"}; 
	@media screen and (max-width: 991px) {
		max-width: ${props => props.imgWidth || "571px"}; 
	};
	@media screen and (max-width: 768px) {
		max-width: ${props => props.imgWidth || "100%"}; 
	};
	img {
		width: 100%
		height: 100%;
	}
`

export default S1App